import React, { useRef, useContext, useEffect, useState } from 'react';
import { PointerIntersectionContext, MeshContext } from '../DandyMesh';
import MaskVerticesCache from './mask-vertices-cache';
import config from '../config.json';
import { Color, Vector3 } from 'three';

export const MAX_MARKER_SIZE = 8;
export const MIN_MARKER_SIZE = 0;

// Save vertex indexes, assume that there are considerable less
// vertices which are marked than total number of vertices.
export const maskVertices = new MaskVerticesCache(config.labels);

// https://blog.logrocket.com/how-to-get-previous-props-state-with-react-hooks/
function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

function useDifference(value) {
    const prev = usePrevious(value) || [];
    const create = value.filter(v => !prev.includes(v));
    const remove = prev.filter(v => !value.includes(v));

    return [create, remove];
}

export default function Marker(props) {
    const {
        marker,
        size,
        setMarkerSize,
        faceIndex,
        visibleLabels,
        orderedVisibleLabels,
        markerGroup,
        markerId,
        saturation,
        hideAllLabels,
        setLabel,
        isSelecting,
        setIsSelecting,
        isAltDown,
        setIsAltDown,
        isControlDown,
        setIsControlDown,
        isShiftDown,
        setIsShiftDown,
        hoverLabel,
        setHoverLabel,
        saveSnapshot,
        firstDown,
        setFirstDown,
        baseColorAttr,
        setBaseColorAttr,
        ...extraProps
    } = props;

    const markerRef = useRef();

    const intersection = useContext(PointerIntersectionContext);
    const mesh = useContext(MeshContext).mesh;

    const [preview, setPreview] = useState([]);
    const [markLabels, clearLabels] = useDifference(visibleLabels);

    const [lastPaintedVertex, setLastPaintedVertex] = useState(null);

    let scale = 1;
    let position = null;
    if (intersection) {
        scale = intersection.distance * 0.025;
        position = intersection.point;
    }

    useEffect(() => {
        if (!mesh) return;

        const originalColorAttribute = mesh.geometry.getAttribute('original_color');

        const colorAttribute = mesh.geometry.getAttribute('color');

        const _baseColorAttr = originalColorAttribute.clone();
        const c = new Color();
        for (let vertex = 0; vertex < _baseColorAttr.count; vertex++) {
            c.setRGB(_baseColorAttr.getX(vertex), _baseColorAttr.getY(vertex), _baseColorAttr.getZ(vertex));

            let hsl = c.getHSL({});
            let s = hsl.s + saturation;

            s = Math.max(0.0, s);
            s = Math.min(1.0, s);

            c.setHSL(hsl.h, s, hsl.l);

            _baseColorAttr.setXYZ(vertex, c.r, c.g, c.b);
            colorAttribute.setXYZ(vertex, c.r, c.g, c.b);
        }

        restoreColorByMasks(maskVertices.listAll(), mesh.geometry, orderedVisibleLabels, _baseColorAttr);

        colorAttribute.needsUpdate = true;
        _baseColorAttr.needsUpdate = true;

        setBaseColorAttr(_baseColorAttr);
    }, [mesh, saturation, orderedVisibleLabels]);

    useEffect(() => {
        if (mesh) {
            maskVertices.reset(mesh.geometry);
        }
    }, [mesh]);

    useEffect(() => {
        if (mesh) {
            clearLabels.forEach(label => hideLabel(label, mesh, orderedVisibleLabels, baseColorAttr));
            markLabels.forEach(label => showLabel(label, mesh, orderedVisibleLabels, baseColorAttr));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mesh, markLabels[0], clearLabels[0]]);

    useEffect(() => {
        if (mesh && marker) {
            showLabel(marker, mesh, orderedVisibleLabels, baseColorAttr);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mesh, marker, orderedVisibleLabels]);

    // Marker box position and orientation
    useEffect(() => {
        if (hideAllLabels || !intersection || !mesh) return;

        const lookAt = intersection.face.normal.clone();
        lookAt.transformDirection(mesh.matrixWorld);
        lookAt.multiplyScalar(10);
        lookAt.add(intersection.point);

        if (lookAt && markerRef.current) {
            markerRef.current.lookAt(lookAt);
        }
    }, [intersection, mesh, hideAllLabels]);

    let markerColor = null;
    if (intersection) {
        if (hoverLabel) {
            markerColor = hoverLabel.color;
        } else if (marker) {
            markerColor =
                intersection.mouse.altKey && intersection.mouse.ctrlKey && intersection.mouse.shiftKey
                    ? marker.color
                    : intersection.mouse.altKey || intersection.mouse.ctrlKey
                    ? 'white'
                    : marker.color;
        } else if (isSelecting) {
            markerColor = 'white';
        }
    }

    // Mesh coloring, and marker color preview
    useEffect(() => {
        if (!mesh || !baseColorAttr || hideAllLabels) return;

        if (preview) {
            restoreColorByMasks(preview, mesh.geometry, orderedVisibleLabels, baseColorAttr);
        }

        if (!intersection) {
            return;
        }

        const buttonDown = intersection.mouse.buttons & 0b001;
        const cameraPan = intersection.mouse.shiftKey;

        const face = intersection.face;

        const altDown = intersection.mouse.altKey;
        const shiftDown = intersection.mouse.shiftKey;
        const controlDown = intersection.mouse.ctrlKey;
        const selecting = controlDown && !shiftDown && !altDown;
        const isFloodFill = controlDown && shiftDown && altDown;
        let selectedLabel = null;

        if (selecting) {
            config.groups.forEach(groupId => {
                const maskAttr = mesh.geometry.getAttribute(`${groupId}_mask`);
                const selectedLabelId = maskAttr.getX(face.a);
                if (selectedLabelId) {
                    // find the visible labels which match the one we see in the mask attributes list
                    // and pick the first one here, if any
                    const selectedLabelVisible = visibleLabels.filter(
                        l => l.id === selectedLabelId && l.group === groupId
                    )[0];
                    if (selectedLabelVisible) {
                        selectedLabel = selectedLabelVisible;
                    }
                }
            });
        }
        setIsSelecting(selecting);
        setIsAltDown(altDown);
        setIsControlDown(controlDown);
        setIsShiftDown(shiftDown);
        setHoverLabel(selectedLabel);

        if (buttonDown && selecting && selectedLabel) {
            setLabel(selectedLabel);
            if (markerGroup != selectedLabel.group) {
                // update marker size if changing group
                setMarkerSize(selectedLabel.markerSize);
            }
        } else if (marker) {
            const colorAttribute = mesh.geometry.getAttribute('color');
            if (selecting) {
                setPreview([]);
            } else {
                const maskAttr = mesh.geometry.getAttribute(`${markerGroup}_mask`);

                let vertices = [face.a, face.b, face.c];
                if (size == 0) {
                    narrowVertices(vertices, position, faceIndex);
                } else {
                    expandVertices(vertices, size, faceIndex);
                }

                const alpha = extraProps.alpha;
                const color = new Color(markerColor);
                const erase = (altDown && !isFloodFill) || extraProps.erase;

                // Render preview
                colorVertices(vertices, color, colorAttribute, 0.75, colorAttribute);
                setPreview(vertices);

                if (buttonDown && (!cameraPan || isFloodFill)) {
                    if (!firstDown) {
                        setFirstDown(true);
                        // save snapshot for undo
                        saveSnapshot();
                    }
                    // we save the last position we painted, and connect to our current vertex
                    const newLastPaintedVertex = vertices[0];
                    connectVertices(vertices, lastPaintedVertex, size, faceIndex);
                    setLastPaintedVertex(newLastPaintedVertex);

                    if (!erase) {
                        if (isFloodFill) {
                            floodVertices(vertices, maskAttr, markerId, faceIndex);
                        }
                        // Color vertices, and set mask value.
                        colorVertices(vertices, color, colorAttribute, alpha, baseColorAttr);
                        setMaskValue(vertices, maskAttr, markerId);
                        maskVertices.mark(markerGroup, markerId, vertices);
                    } else {
                        // Erase
                        // if shift is held down, we only erase from the selected class
                        const eraseVertices = controlDown
                            ? vertices.filter(vertexId => maskAttr.getX(vertexId) === markerId)
                            : vertices;
                        setMaskValue(eraseVertices, maskAttr, 0);
                        maskVertices.clear(markerGroup, markerId, eraseVertices);
                        restoreColorByMasks(eraseVertices, mesh.geometry, orderedVisibleLabels, baseColorAttr);
                    }
                } else {
                    if (firstDown) {
                        setFirstDown(false);
                    }
                    setLastPaintedVertex(null);
                }
            }
            colorAttribute.needsUpdate = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [marker, intersection, mesh, visibleLabels]);

    useEffect(() => {
        if (!mesh) return;

        if (hideAllLabels) {
            const vertices = maskVertices.listAll();
            preview.forEach(v => vertices.add(v));
            restoreColorByMasks(vertices, mesh.geometry, [], baseColorAttr);
        } else {
            restoreColorByMasks(maskVertices.listAll(), mesh.geometry, orderedVisibleLabels, baseColorAttr);
        }
    }, [hideAllLabels, baseColorAttr, mesh, orderedVisibleLabels, preview]);

    return (
        intersection &&
        !hideAllLabels && (
            <mesh ref={markerRef} visible={extraProps.visible} position={position} scale={[scale, scale, scale]}>
                <boxGeometry attach="geometry" args={[0.21, 0.21, 7.5]} />
                <meshStandardMaterial attach="material" color={markerColor} />
            </mesh>
        )
    );
}

export function restoreColorByMasks(vertices, geometry, visibleLabels, baseColorAttr) {
    if (!geometry || !baseColorAttr) {
        return;
    }
    const colorAttribute = geometry.getAttribute('color');
    vertices.forEach(vertex => {
        colorAttribute.copyAt(vertex, baseColorAttr, vertex);
    });
    // make a map of visible labels by group and id
    const visibleLabelColors = {};
    visibleLabels.forEach(({ group: groupId, id: labelId, color: labelColor }) => {
        visibleLabelColors[`${groupId}-${labelId}`] = labelColor;
    });
    // iterate through groups, then each vertex
    config.groups.forEach(groupId => {
        const maskAttribute = geometry.getAttribute(`${groupId}_mask`);
        vertices.forEach(vertex => {
            const labelId = maskAttribute.getX(vertex);
            if (!labelId) return;
            const labelColor = visibleLabelColors[`${groupId}-${labelId}`];
            if (!labelColor) return;
            const color = new Color(labelColor);
            colorAttribute.setXYZ(vertex, color.r, color.g, color.b);
        });
    });
    colorAttribute.needsUpdate = true;
}

export function restoreAllColorsByMasks(geometry, visibleLabels, baseColorAttr) {
    if (!geometry || !baseColorAttr) {
        return;
    }
    geometry.setAttribute('color', baseColorAttr.clone());
    const colorAttribute = geometry.getAttribute('color');
    // make a map of visible labels by group and id
    const visibleLabelColors = {};
    visibleLabels.forEach(({ group: groupId, id: labelId, color: labelColor }) => {
        visibleLabelColors[`${groupId}-${labelId}`] = labelColor;
    });
    // iterate through groups, then each vertex
    config.groups.forEach(groupId => {
        const maskAttribute = geometry.getAttribute(`${groupId}_mask`);
        for (let vertex = 0; vertex < maskAttribute.count; vertex++) {
            const labelId = maskAttribute.getX(vertex);
            if (!labelId) continue;
            const labelColor = visibleLabelColors[`${groupId}-${labelId}`];
            if (!labelColor) continue;
            const color = new Color(labelColor);
            colorAttribute.setXYZ(vertex, color.r, color.g, color.b);
        }
    });
    colorAttribute.needsUpdate = true;
}

function hideLabel({ group: groupId, id: labelId }, mesh, visibleLabels, baseColorAttr) {
    const labelName = maskVertices.labelName(groupId, labelId);
    restoreColorByMasks(maskVertices[labelName], mesh.geometry, visibleLabels, baseColorAttr);
}

function showLabel({ group: groupId, id: labelId }, mesh, visibleLabels, baseColorAttr) {
    const labelName = maskVertices.labelName(groupId, labelId);
    restoreColorByMasks(maskVertices[labelName], mesh.geometry, visibleLabels, baseColorAttr);
}

function setMaskValue(vertices, maskAttr, value) {
    vertices.forEach(vertex => {
        maskAttr.setX(vertex, value);
    });
}

function colorVertices(vertices, color, colorAttribute, alpha, originalColorAttribute) {
    vertices.forEach(vertex => {
        let mix = color;

        if (alpha) {
            mix = new Color(
                originalColorAttribute.getX(vertex),
                originalColorAttribute.getY(vertex),
                originalColorAttribute.getZ(vertex)
            );
            mix.lerp(color, alpha);
        }

        colorAttribute.setXYZ(vertex, mix.r, mix.g, mix.b);
    });
}

function expandVertices(vertices, expansionFactor, index) {
    for (let i = 1; i < expansionFactor || 0; i++) {
        const faces = [];

        vertices.forEach(v => {
            faces.push(...index.getFacesByVertexIndex(v));
        });

        faces.forEach(faceIndex => {
            [faceIndex * 3, faceIndex * 3 + 1, faceIndex * 3 + 2].forEach(vertexIndex => {
                const vi = index.getVertexIndex(vertexIndex);
                if (!vertices.includes(vi)) {
                    vertices.push(vi);
                }
            });
        });
    }
}

function narrowVertices(vertices, point, index) {
    if (!vertices) {
        return;
    }
    // pick the closest vertex to point
    let minDist = -1;
    let minVertexIndex;
    vertices.forEach(vertexIndex => {
        const pos = new Vector3();
        [pos.x, pos.y, pos.z] = index.getVertexPosition(vertexIndex);
        const dist = point.distanceTo(pos);
        if (minDist < 0 || dist < minDist) {
            minDist = dist;
            minVertexIndex = vertexIndex;
        }
    });
    vertices.length = 0;
    vertices.push(minVertexIndex);
}

function connectVertices(vertices, lastVertex, expansionFactor, index) {
    if (!vertices || !lastVertex) {
        return;
    }
    // greedily pick the path of vertices to lastVertex
    const lastPos = new Vector3();
    [lastPos.x, lastPos.y, lastPos.z] = index.getVertexPosition(lastVertex);
    let currentVertex = vertices[0];
    let maxIters = 100;
    while (!vertices.includes(lastVertex) && maxIters >= 0) {
        maxIters--;
        const candidateVertices = [currentVertex];
        expandVertices(candidateVertices, 2, index);
        let minDist = -1;
        let minVertexIndex = null;
        candidateVertices.forEach(vertexIndex => {
            const pos = new Vector3();
            [pos.x, pos.y, pos.z] = index.getVertexPosition(vertexIndex);
            const dist = lastPos.distanceTo(pos);
            if (minDist < 0 || dist < minDist) {
                minDist = dist;
                minVertexIndex = vertexIndex;
            }
        });
        if (!minVertexIndex) {
            break;
        }
        const paintVertices = [minVertexIndex];
        expandVertices(paintVertices, expansionFactor, index);
        paintVertices.forEach(vertexIndex => {
            if (!vertices.includes(vertexIndex)) {
                vertices.push(vertexIndex);
            }
        });
        currentVertex = minVertexIndex;
    }
}

function floodVertices(vertices, maskAttr, markerId, index) {
    if (!vertices || !markerId) {
        return;
    }
    let maxIters = 10000;
    const initialVertex = vertices[0];
    const pendingVertices = [initialVertex];
    const matchId = maskAttr.getX(initialVertex);
    vertices.length = 0;
    while (pendingVertices.length > 0 && maxIters >= 0) {
        const currentVertex = pendingVertices.shift();
        const currentId = maskAttr.getX(currentVertex);
        if (currentId == markerId || currentId != matchId) {
            continue;
        }
        if (vertices.includes(currentVertex)) {
            continue;
        }
        maxIters--;
        vertices.push(currentVertex);
        const candidateVertices = [currentVertex];
        expandVertices(candidateVertices, 2, index);
        pendingVertices.push(...candidateVertices);
    }
}

import React from 'react';

import styles from './styles.module.css';

function ProgressBar({ progress }) {
    progress = parseInt(progress * 100);
    return (
        <div className={styles.container}>
            <div className={styles.filler} style={{ width: `${progress}%` }}>
                <span className={styles.label}>{`${progress}%`}</span>
            </div>
        </div>
    );
}

export default ProgressBar;

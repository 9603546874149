export default class MaskVerticesCache {
    constructor(labels) {
        this.labels = labels;

        labels.forEach(l => {
            this[l.label] = new Set(); // indexed on label name
        });
    }

    reset(geometry) {
        geometry &&
            this.labels.forEach(l => {
                // This might happen when we are loading new mesh
                this[l.label].clear();

                geometry.getAttribute(`${l.group}_mask`).array.forEach((maskValue, index) => {
                    if (maskValue === l.id) {
                        this[l.label].add(index);
                    }
                });
            });
    }

    listAll() {
        const result = new Set();
        this.labels.forEach(l => {
            this[l.label].forEach(v => {
                result.add(v);
            });
        });
        return result;
    }

    labelName(groupId, labelId) {
        const entry = this.labels.filter(l => l.group == groupId && l.id == labelId)[0];
        return entry.label;
    }

    mark(groupId, labelId, vertices) {
        const labelName = this.labelName(groupId, labelId);
        vertices.forEach(v => this[labelName].add(v));
    }

    clear(groupId, labelId, vertices) {
        const labelName = this.labelName(groupId, labelId);
        vertices.forEach(v => this[labelName].delete(v));
    }
}

import styles from './input-dial.css';

export default function InputDial({ name, value, min, max, onChange }) {
    return (
        <div className={'inputDial'}>
            <label>
                {name}: {value}
            </label>
            <button
                onClick={() => {
                    if (value === min) return;
                    onChange(value - 1);
                }}
            >
                −
            </button>
            <button
                onClick={() => {
                    if (value === max) return;
                    onChange(value + 1);
                }}
            >
                +
            </button>
        </div>
    );
}

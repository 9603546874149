import React from 'react';

export default function CameraOptions({ cameraOptions, setCameraOptions }) {
    function handleNear(event) {
        const opt = {
            ...cameraOptions,
            near: parseFloat(event.target.value),
        };
        setCameraOptions(opt);
    }

    return (
        <>
            <label>Camera near</label>
            <input
                id="camera-near"
                type="range"
                min="0.1"
                max="100"
                value={cameraOptions.near}
                onChange={handleNear}
            ></input>
        </>
    );
}

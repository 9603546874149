import React, { useState, useEffect } from 'react';

import ImportExport from '../ImportExport';
import LabelMetadata from '../LabelMetadata';
import LabelSelect from '../LabelSelect';
import { MAX_MARKER_SIZE, MIN_MARKER_SIZE } from '../Marker';
import CloudBucket from '../CloudBucket';
import CameraOptions from '../CameraOptions';

import styles from './styles.module.css';
import InputDial from './InputDial';
import AppearanceOptions from '../AppearanceOptions';

function Controls({
    label,
    setLabel,
    setMarker,
    marker,
    visibleLabels,
    setVisibleLabels,
    markerSize,
    setMarkerSize,
    isFilterFocused,
    setIsFilterFocused,
    ...rest
}) {
    const [userInfo, setUserInfo] = useState({});
    const [bucket, setBucket] = useState(null);

    useEffect(() => {
        if (!bucket) {
            let new_bucket = new CloudBucket();
            setBucket(new_bucket);
        }
    }, [bucket, setBucket]);

    useEffect(() => {
        if (!setMarker) return;
        setMarker(label ? { group: label.group, id: label.id, label: label.label, color: label.color } : null);
    }, [setMarker, label]);

    return (
        <div className={styles.container}>
            <ImportExport {...{ userInfo, setUserInfo, bucket }} {...rest} />
            <LabelMetadata {...{ userInfo, setUserInfo }} {...rest} />
            <div>Selected class: {`${label ? label.label : 'none'}`}</div>
            <LabelSelect
                onChange={l => {
                    setLabel(l);
                    if (l && (!label || l.group != label.group)) {
                        // adjust size only if we switch groups
                        setMarkerSize(l.markerSize);
                    }
                }}
                label={label}
                visibleLabels={visibleLabels}
                setVisibleLabels={setVisibleLabels}
                isFilterFocused={isFilterFocused}
                setIsFilterFocused={setIsFilterFocused}
                {...rest}
            />
            <InputDial
                name={'Marker size'}
                value={markerSize}
                onChange={setMarkerSize}
                min={MIN_MARKER_SIZE}
                max={MAX_MARKER_SIZE}
            />
            <CameraOptions {...rest} />
            <AppearanceOptions {...rest} />
        </div>
    );
}

export default Controls;

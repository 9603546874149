import React, { useRef, useState, Suspense, useEffect, useMemo } from 'react';
import { Canvas, useThree } from '@react-three/fiber';
import * as THREE from 'three';

import Marker, { MAX_MARKER_SIZE, MIN_MARKER_SIZE } from '../Marker';
import CameraControls from '../CameraControls';
import Controls from '../Controls';
import DandyMesh from '../DandyMesh';
import useKeyboardShortcuts from '../useKeyboardShortcuts';
import useOperationStack from '../useOperationStack';
import { Loading } from './Loading';
import CameraRig from './CameraRig';
import HelpKey from '../HelpKey';

import options from '../config.json';

function Raycaster(props) {
    const { raycaster } = useThree();

    useEffect(() => {
        raycaster.near = props.near;
    }, [props.near, raycaster]);

    return null;
}

function VertexPainter() {
    const [loadedAnnotationData, setLoadedAnnotationData] = useState({});
    const [annotationData, setAnnotationData] = useState({});
    const [geometry, setGeometry] = useState(null);
    const [faceIndex, setFaceIndex] = useState(null);
    const initialCameraPosition = [-40, 30, 0];
    const initialCameraDistance = Math.sqrt(initialCameraPosition.reduce((acc, curr) => acc + curr ** 2));
    const [cameraDistance, setCameraDistance] = useState(initialCameraDistance);
    const [cameraOptions, setCameraOptions] = useState({
        fov: 75,
        position: initialCameraPosition,
        near: 1,
    });
    const [appearanceOptions, setAppearanceOptions] = useState({
        saturation: -0.1,
        ambientLightIntensity: 0.6,
        pointLightIntensity: 0,
        directionalLightIntensity: 0.6,
    });

    const mesh = useRef();
    const camera = useRef();
    const light = useRef();

    const [visibleLabels, setVisibleLabels] = useState(options.labels);
    const [label, setLabel] = useState(null);
    const [marker, setMarker] = useState(null);
    const [isSelecting, setIsSelecting] = useState(null); // whether we are selecting a label via ctrl-click (i.e. holding down control)
    const [isAltDown, setIsAltDown] = useState(false); // whether we are holding alt
    const [isControlDown, setIsControlDown] = useState(false); // whether we are holding control
    const [isShiftDown, setIsShiftDown] = useState(false); // whether we are holding shift
    const [hoverLabel, setHoverLabel] = useState(null);
    const [markerSize, setMarkerSize] = useState(4);

    const [mouseHover, setMouseHover] = useState(false);
    const meshPointerEvents = {
        onPointerOver: event => {
            setMouseHover(true);
        },
        onPointerOut: event => {
            setMouseHover(false);
        },
    };

    const [isFilterFocused, setIsFilterFocused] = useState(false);
    const [hideAllLabels, setHideAllLabels] = useState(false);
    useKeyboardShortcuts(['Digit0'], () => {
        setHideAllLabels(!hideAllLabels);
    });

    useKeyboardShortcuts(['Equal', 'NumpadAdd'], code => {
        if (markerSize === MAX_MARKER_SIZE) return;
        setMarkerSize(markerSize + 1);
    });

    useKeyboardShortcuts(['Minus', 'NumpadSubtract'], code => {
        if (markerSize === MIN_MARKER_SIZE) return;
        setMarkerSize(markerSize - 1);
    });

    function handleCameraChange() {
        if (camera.current) {
            const { x, y, z } = camera.current.position;
            // Calculate projection distance of camera to the plane it is looking at.
            // This would be distance to origin except when we pan, the point we
            // are looking at would be a translation from the origin.
            const direction = new THREE.Vector3();
            camera.current.getWorldDirection(direction);
            const dotProduct = camera.current.position.dot(direction);
            setCameraDistance(-dotProduct);
            if (light.current) {
                light.current.position.set(x, y, z);
            }
            camera.current.updateProjectionMatrix();
        }
    }

    useEffect(() => {
        handleCameraChange();
    });

    const activeMarkerHover = mouseHover && (marker != null || isSelecting);
    const markerGroup = marker ? marker.group : null;
    const markerId = marker ? marker.id : null;

    const orderedVisibleLabels = useMemo(() => {
        // Move active label to the end of the labels list,
        // this way restoreColorByMasks will render active
        // label on top
        const orderedVisibleLabels = visibleLabels.filter(l => l.group !== markerGroup || l.id !== markerId);
        const visibleActiveLabel = visibleLabels.find(l => l.group === markerGroup && l.id === markerId);
        visibleActiveLabel && orderedVisibleLabels.push(visibleActiveLabel);
        return orderedVisibleLabels;
    }, [visibleLabels, markerGroup, markerId]);

    const { saveSnapshot, firstDown, setFirstDown, isDirty, setIsDirty, baseColorAttr, setBaseColorAttr } =
        useOperationStack({
            geometry,
            orderedVisibleLabels,
            annotationData,
            setAnnotationData,
        });

    // don't allow rotation if:
    // - we have a selected painting label, or
    // - we are holding ctrl to select a label on the mesh
    // and we are not holding shift (which bypasses the above)
    const isFloodFill = isShiftDown && isAltDown && isControlDown;
    const noRotate = ((label != null || isSelecting) && !isShiftDown) || isFloodFill;

    // have cameraNear take into account the current camera zoom and pan
    const cameraNear = cameraOptions.near + cameraDistance - initialCameraDistance;

    return (
        <>
            <Controls
                mesh={mesh}
                geometry={geometry}
                setGeometry={setGeometry}
                setFaceIndex={setFaceIndex}
                setMarker={setMarker}
                {...{
                    label,
                    setLabel,
                    visibleLabels,
                    setVisibleLabels,
                    hideAllLabels,
                    setHideAllLabels,
                    markerSize,
                    setMarkerSize,
                    cameraOptions,
                    setCameraOptions,
                    appearanceOptions,
                    setAppearanceOptions,
                    isFilterFocused,
                    setIsFilterFocused,
                    loadedAnnotationData,
                    setLoadedAnnotationData,
                    annotationData,
                    setAnnotationData,
                    saveSnapshot,
                    isDirty,
                    setIsDirty,
                }}
            />
            <HelpKey showModifier={isFilterFocused} />

            <Canvas linear flat>
                <CameraControls onChange={handleCameraChange} noRotate={noRotate} rotateSpeed={4} />
                <CameraRig cameraRef={camera} {...cameraOptions} near={cameraNear} />
                <Raycaster near={cameraNear} />
                <ambientLight intensity={appearanceOptions.ambientLightIntensity} />
                <pointLight position={[0, 10, 0]} intensity={appearanceOptions.pointLightIntensity} />
                <pointLight position={[0, -30, 0]} intensity={appearanceOptions.pointLightIntensity} />
                <directionalLight ref={light} intensity={appearanceOptions.directionalLightIntensity} />

                <Suspense fallback={<Loading />}>
                    <DandyMesh geometry={geometry} meshRef={mesh} {...meshPointerEvents}>
                        <Marker
                            marker={marker}
                            markerGroup={markerGroup}
                            markerId={markerId}
                            visibleLabels={visibleLabels}
                            orderedVisibleLabels={orderedVisibleLabels}
                            visible={activeMarkerHover}
                            saturation={appearanceOptions.saturation}
                            hideAllLabels={hideAllLabels}
                            setLabel={setLabel}
                            erase={false}
                            faceIndex={faceIndex}
                            size={markerSize}
                            setMarkerSize={setMarkerSize}
                            alpha={false}
                            isSelecting={isSelecting}
                            setIsSelecting={setIsSelecting}
                            isAltDown={isAltDown}
                            setIsAltDown={setIsAltDown}
                            isControlDown={isControlDown}
                            setIsControlDown={setIsControlDown}
                            isShiftDown={isShiftDown}
                            setIsShiftDown={setIsShiftDown}
                            hoverLabel={hoverLabel}
                            setHoverLabel={setHoverLabel}
                            saveSnapshot={saveSnapshot}
                            firstDown={firstDown}
                            setFirstDown={setFirstDown}
                            baseColorAttr={baseColorAttr}
                            setBaseColorAttr={setBaseColorAttr}
                        ></Marker>
                    </DandyMesh>
                </Suspense>
            </Canvas>
        </>
    );
}

export default VertexPainter;

import { useCallback, useEffect } from 'react';

function useKeyboardShortcuts(keyCodes, onPress, eventName) {
    // useCallback prevents function from being redefined every time component is rendered
    const memoizedKeyUpListener = useCallback(
        e => {
            const { code } = e;
            if (!keyCodes.includes(code)) return;
            onPress(code, e);
        },
        [keyCodes, onPress]
    );

    useEffect(() => {
        window.addEventListener(eventName || 'keyup', memoizedKeyUpListener, true);
        return () => window.removeEventListener(eventName || 'keyup', memoizedKeyUpListener, true);
    }, [memoizedKeyUpListener]);
}

export default useKeyboardShortcuts;

export default function AppearanceOptions({ appearanceOptions, setAppearanceOptions }) {
    const { saturation, pointLightIntensity, ambientLightIntensity, directionalLightIntensity } = appearanceOptions;

    function apply(changes) {
        setAppearanceOptions({
            ...appearanceOptions,
            ...changes,
        });
    }

    return (
        <>
            <label>Mesh colors saturation</label>
            <input
                type={'range'}
                min={-1.0}
                max={1.0}
                step={0.01}
                value={saturation}
                onChange={e => apply({ saturation: parseFloat(e.target.value) })}
            />

            <label>Point Light Intesity</label>
            <input
                type={'range'}
                min={0.0}
                max={1.0}
                step={0.0125}
                value={pointLightIntensity}
                onChange={e => apply({ pointLightIntensity: parseFloat(e.target.value) })}
            />

            <label>Directional Light Intesity</label>
            <input
                type={'range'}
                min={0.0}
                max={2.0}
                step={0.0125}
                value={directionalLightIntensity}
                onChange={e => apply({ directionalLightIntensity: parseFloat(e.target.value) })}
            />

            <label>Ambient Light Intesity</label>
            <input
                type={'range'}
                min={0.0}
                max={1.0}
                step={0.0125}
                value={ambientLightIntensity}
                onChange={e => apply({ ambientLightIntensity: parseFloat(e.target.value) })}
            />
        </>
    );
}

class DandyLabelLoader {
    constructor(setLoadedAnnotationData, setAnnotationData) {
        this.setLoadedAnnotationData = setLoadedAnnotationData;
        this.setAnnotationData = setAnnotationData;
    }

    load(data, onLoad, onProgress, onError) {
        console.log('loading label data', data);
        // this triggers the update of the mesh once geometry is also available
        this.setLoadedAnnotationData(data || {});
        // this is the state we store for writing labels
        this.setAnnotationData(data || {});
        onLoad?.(data);
    }
}

export { DandyLabelLoader };

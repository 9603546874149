import React from 'react';
import ShortcutIcon from '../ShortcutIcon';
import useLocalStorage from '../useLocalStorage';

import styles from './styles.module.css';

function HelpKey({ showModifier }) {
    const [visible, setVisible] = useLocalStorage('showHelpKey', true);
    const shortcutPrefix = showModifier ? '^' : '';

    return (
        <div className={styles.container}>
            <p>
                Keyboard shortcuts <button onClick={() => setVisible(!visible)}>{visible ? 'Hide' : 'Show'}</button>
            </p>
            <div className={`${visible ? '' : styles.invisible}`}>
                <br />
                <p>
                    Number keys <ShortcutIcon character={`${shortcutPrefix}1`} />-
                    <ShortcutIcon character={`${shortcutPrefix}9`} />: Change active label
                </p>
                <p>
                    <ShortcutIcon character={`${shortcutPrefix}-`} /> and{' '}
                    <ShortcutIcon character={`${shortcutPrefix}+`} />/
                    <ShortcutIcon character={`${shortcutPrefix}=`} />: Change marker size
                </p>
                <p>
                    <ShortcutIcon character={`${shortcutPrefix}0`} />: Toggle all labels visibility
                </p>
                <p>
                    <ShortcutIcon character="Hold Shift" />: Allow rotation
                </p>
                <p>
                    <ShortcutIcon character="Middle-Click Drag" />: Camera panning
                </p>
                <p>
                    <ShortcutIcon character="Ctrl-Click" />: Pick label class
                </p>
                <p>
                    <ShortcutIcon character="Alt-Click" />/<ShortcutIcon character="Option-Click" />: Erase group class
                </p>
                <p>
                    <ShortcutIcon character="Ctrl-Alt-Click" />/<ShortcutIcon character="Ctrl-Option-Click" />: Erase
                    label class
                </p>
                <p>
                    <ShortcutIcon character="Ctrl-Shift-Alt-Click" />/
                    <ShortcutIcon character="Ctrl-Shift-Option-Click" />: Fill label class
                </p>
                <p>
                    <ShortcutIcon character="Ctrl-Z" /> or <ShortcutIcon character="Cmd-Z" />: Undo last paint
                </p>
                <p>
                    <ShortcutIcon character="Ctrl-Shift-Z" /> or <ShortcutIcon character="Cmd-Shift-Z" />: Redo last
                    paint
                </p>
            </div>
        </div>
    );
}

export default HelpKey;
